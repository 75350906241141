(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("rb_wixui.thunderbolt[ComboBoxInput]", ["react", "reactDOM"], factory);
	else if(typeof exports === 'object')
		exports["rb_wixui.thunderbolt[ComboBoxInput]"] = factory(require("react"), require("react-dom"));
	else
		root["rb_wixui.thunderbolt[ComboBoxInput]"] = factory(root["React"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__38__) {
return 